<template>
	<div id="app">
		<Baner
			:opened="opened"
			@opened="setOpened"
			@dealer_name="setDealerName"
			@dealer_id="setDealerId"
			@city="setCity"
			:brand_id="brandId"
			:dealer_id="dealerId"
			@modalOpen="setModal"
			:city="city"
		/>
		<!-- <div class="container c-irregularity">
			<a
				href="https://porscheinterauto.pl/whistleblower-system/"
				target="_blank"
			>
				Whistleblower System
			</a>
		</div> -->
		<Form
			@opened="setOpened"
			:dealer_name="dealerName"
			:dealer_id="dealerId"
			:city="city"
			@brand_id="setBrandId"
			@city="setCity"
		/>
	</div>
</template>

<script>
	import Baner from "./components/Baner.vue";
	import Form from "./components/Form.vue";

	export default {
		name: "App",
		components: {
			Baner,
			Form,
		},
		data() {
			return {
				opened: false,
				dealerName: "",
				dealerId: "",
				brandId: "",
				city: "",
			};
		},
		methods: {
			setOpened(e) {
				this.opened = e;
			},
			setDealerName(name) {
				this.dealerName = name;
			},
			setDealerId(id) {
				this.dealerId = id;
			},
			setBrandId(id) {
				this.brandId = id;
			},
			setModal(val) {
				this.opened = val;
			},
			setCity(val) {
				this.city = val;
			},
		},
	};
</script>

<style lang="scss">
	@import "assets/fonts/stylesheet.css";
	html,
	body {
		scroll-behavior: smooth;
	}
	body {
		margin: 0px;
		padding: 0px;
		font-family: "roboto";
	}
	p,
	h1,
	h2,
	h3,
	h4 {
		margin: 0;
	}
	a {
		text-decoration: none;
		color: inherit;
	}
	img {
		max-width: 100%;
	}
	fieldset.km {
		flex-direction: column;
		background-color: #1b3354;
		bottom: 0;
		top: none !important;
		left: -2px;
		width: calc(100% - 24px);
		height: fit-content;
		margin-right: 15px;
		top: calc(100% - 60px);
		@media (max-width: 1274px) {
			top: calc(100% - 80px);
			height: fit-content;
		}
		@media (max-width: 551px) {
			top: calc(100% - 60px);
			height: fit-content;
			align-items: flex-start;
		}

		.radio__label {
			color: white !important;
			font: normal normal normal 18px/29px Roboto;
		}
	}
	fieldset {
		border: none;
		display: flex !important;
		top: 20px;
		z-index: 9999;
		position: absolute;
		width: 100%;
		column-gap: 20px;
		@media (max-width: 430px) {
			flex-direction: column;
			align-items: center;
		}
	}
	.c-irregularity {
		text-align: right;
		font-size: 12px;
		font-weight: 700;
		color: #000;
		padding-bottom: 2rem;
	}

	.slide-enter-active {
		-moz-transition-duration: 0.3s;
		-webkit-transition-duration: 0.3s;
		-o-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-moz-transition-timing-function: ease-in;
		-webkit-transition-timing-function: ease-in;
		-o-transition-timing-function: ease-in;
		transition-timing-function: ease-in;
	}

	.slide-leave-active {
		-moz-transition-duration: 0.3s;
		-webkit-transition-duration: 0.3s;
		-o-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
		-webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
		-o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
		transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	}

	.slide-enter-to,
	.slide-leave {
		max-height: 100px;
		overflow: hidden;
	}

	.slide-enter,
	.slide-leave-to {
		overflow: hidden;
		max-height: 0;
	}
</style>
