var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.openModal)?_c('div',{staticClass:"accordion__absolute"},[_c('a',{staticClass:"accordion__close",on:{"click":function($event){return _vm.$emit('openModal', false)}}},[_vm._v("❌")]),_c('div',{},[_c('div',{},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hide),expression:"hide"}],staticClass:"accordion"},_vm._l((_vm.dealerList.filter(
						function (item) { return item.city === _vm.city; }
					)),function(dealer,i){return _c('div',{key:dealer.id,staticClass:"accordion__row"},[_c('div',{staticClass:"accordion__title",on:{"click":function($event){return _vm.selectRow(i)}}},[_c('h2',[_vm._v(_vm._s(dealer.name))]),_c('span',[_c('img',{class:{ arrowActive: _vm.selectedRow == i },attrs:{"src":require("@/assets/images/elem/arrow-dow.png"),"alt":""}})])]),_c('transition',{attrs:{"name":"slide"}},[(_vm.selectedRow == i)?_c('div',{staticClass:"accordion__hidden"},[_c('div',{staticClass:"accordion__content"},[_c('div',{},[_c('p',{staticClass:"accordion__text"},[_vm._v(" "+_vm._s(dealer.city)+" "+_vm._s(dealer.address)+" "),_c('br'),_vm._v(" Godziny otwarcia: "),_c('br'),_vm._v(" Pn-Pt: "+_vm._s(dealer.service_hours)+" "),_c('br'),_vm._v(" Sb: "+_vm._s(dealer.service_hours_saturday)+" "),(dealer.id === 11)?[_c('br'),_c('br'),_c('b',[_vm._v(" Tymczasowa lokalizacja:"),_c('br'),_vm._v("Połczyńska 125 ")])]:_vm._e()],2),_c('div',{staticClass:"accordion__button",on:{"click":function($event){return _vm.setDealer(dealer.id, dealer.name, dealer.brand_id, dealer.service_hours_saturday)}}},[_vm._v(" Wybierz ")])]),_c('div',{},[_c('div',{staticClass:"accordion__images"},[(
												_vm.brandsAndServices.VW.includes(dealer.id)
											)?_c('div',{staticClass:"accordion__logo"},[_c('img',{attrs:{"src":require("@/assets/images/vw-small.png"),"alt":""}})]):_vm._e(),(
												_vm.brandsAndServices.VWD.includes(dealer.id)
											)?_c('div',{staticClass:"accordion__logo"},[_c('img',{attrs:{"src":require("@/assets/images/vwd.png"),"alt":""}})]):_vm._e(),(
												_vm.brandsAndServices.Audi.includes(dealer.id)
											)?_c('div',{staticClass:"accordion__logo"},[_c('img',{attrs:{"src":require("@/assets/images/audi-small.png"),"alt":""}})]):_vm._e(),(
												_vm.brandsAndServices.SKODA.includes(dealer.id)
											)?_c('div',{staticClass:"accordion__logo"},[_c('img',{staticStyle:{"width":"85px"},attrs:{"src":require("@/assets/images/Skoda_Serwis_vertical_RGB_Electric_Green.png"),"alt":"Skoda serwis"}})]):_vm._e(),(
												_vm.brandsAndServices.Bentley.includes(dealer.id)
											)?_c('div',{staticClass:"accordion__logo"},[_c('img',{attrs:{"src":require("@/assets/images/bentley-small.jpg"),"alt":""}})]):_vm._e(),(_vm.brandsAndServices.Lamborghini.includes(dealer.id))?_c('div',{staticClass:"accordion__logo"},[_c('img',{attrs:{"src":require("@/assets/images/lambo-small.jpg"),"alt":""}})]):_vm._e(),(
												_vm.brandsAndServices.Porsche.includes(dealer.id)
											)?_c('div',{staticClass:"accordion__logo"},[_c('img',{attrs:{"src":require("@/assets/images/porsche-small.jpg"),"alt":""}})]):_vm._e()])])])]):_vm._e()])],1)}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }