import "@babel/polyfill";
import Vue from 'vue'
import App from './App.vue'
import VCalendar from 'v-calendar';
import store from './store'
import axios from 'axios';

import vaLittle from './valittle.js'
import router from './router'

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "UA-5007400-9" }
});

Vue.prototype.$http = axios;

Vue.config.productionTip = false
Vue.use(VCalendar, {
  componentPrefix: 'vc'
});
Vue.use(vaLittle);
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
